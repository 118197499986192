import { useState, useContext, useEffect } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase.config';
import { useAuth } from './AuthContext';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {
  List,
  CardChecklist,
  Sliders,
  RewindBtn,
  BoxArrowRight,
  EggFried,
  MenuApp,
  Cart2,
  Boxes,
} from 'react-bootstrap-icons';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Alert from 'react-bootstrap/Alert';
import { DataContext } from './DataProvider';

const Layout = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { getUserPrefs, userPrefs, settings } = useContext(DataContext);

  const logout = async () => {
    // console.log('run logout...');
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  const [showOffCanvasMenu, setOffCanvasMenu] = useState(false);
  const handleOffCanvasMenuClose = () => setOffCanvasMenu(false);
  const handleOffCanvasMenuShow = () => setOffCanvasMenu(true);

  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref || 'dark';

  useEffect(() => {
    getUserPrefs(currentUser.uid);
  }, [currentUser.uid, getUserPrefs, userPrefs]);

  return (
    <>
      {settings.version !== global.config.version && (
        <Alert variant={colorPref}>
          <Alert.Heading>
            <strong className="me-auto">Notice</strong>
          </Alert.Heading>
          New version {settings.version} available! Refresh to update.
        </Alert>
      )}

      <Navbar
        id="navbar-shop"
        fixed="top"
        bg={colorPref}
        // data-bs-theme={colorPref === 'warning' ? 'light' : 'dark'}
        data-bs-theme="dark"
      >
        <Container
          // className={colorPref === 'warning' ? 'text-black' : 'text-white'}
          className="text-white"
        >
          <div className="shopping-mode-text">
            Shopping Mode: <b>Enabled</b>&nbsp; &nbsp; &bull; &nbsp; &nbsp;
            Shopping History: <b>On</b>
          </div>
        </Container>
      </Navbar>

      <Navbar
        id="navbar-top"
        fixed="top"
        bg={colorPref}
        // data-bs-theme={colorPref === 'warning' ? 'light' : 'dark'}
        data-bs-theme="dark"
      >
        <Container>
          <Nav className="w-100">
            <Nav.Item>
              <Nav.Link className="navbar-brand">
                <List size={24} onClick={handleOffCanvasMenuShow} />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="ms-auto">
              <Nav.Link className="navbar-brand">
                <img
                  alt="Meal Buddy"
                  className="me-2 navbar-2"
                  src="/buddy-logo.png"
                  width={28}
                />
                Meal Buddy{process.env.NODE_ENV === 'development' && ' DEV'}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Container>
      </Navbar>

      <Outlet />

      <Navbar
        id="navbar-bottom"
        fixed="bottom"
        // bg={process.env.NODE_ENV === 'development' ? colorPref : 'dark'}
        bg={colorPref}
        // data-bs-theme={colorPref === 'warning' ? 'light' : 'dark'}
        data-bs-theme="dark"
      >
        <Container className="navColor">
          <Navbar.Brand as={Link} to="/">
            <div className="text-center">
              <MenuApp size={20} />
            </div>
            <div className="menu-text">Menu</div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/meals">
            <div className="text-center">
              <EggFried size={20} />
            </div>
            <div className="menu-text">Meals</div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/shop">
            <div className="text-center">
              <Cart2 size={20} />
            </div>
            <div className="menu-text">Shop</div>
          </Navbar.Brand>
          <Navbar.Brand as={Link} to="/items">
            <div className="text-center">
              <Boxes size={20} />
            </div>
            <div className="menu-text">Items</div>
          </Navbar.Brand>
        </Container>
      </Navbar>

      <Offcanvas
        className="offcanvas-main"
        show={showOffCanvasMenu}
        onHide={handleOffCanvasMenuClose}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Options</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="offcanvas-option">
            <Link to="/lists" onClick={handleOffCanvasMenuClose}>
              <CardChecklist size={21} className="me-2 mb-1" />
              Lists
            </Link>
          </div>
          <div className="offcanvas-option">
            <Link to="/settings" onClick={handleOffCanvasMenuClose}>
              <Sliders size={21} className="me-2 mb-1" />
              Settings
            </Link>
          </div>
          <div className="offcanvas-option">
            <Link to="/history" onClick={handleOffCanvasMenuClose}>
              <RewindBtn size={21} className="me-2 mb-1" />
              History
            </Link>
          </div>
          {/* <div className="mb-2">
            <Link to="/data/input" onClick={handleOffCanvasMenuClose}>
              <CloudUpload size={18} className="me-2 mb-1" />
              Import Data
            </Link>
          </div> */}
          <hr />
          <div className="offcanvas-option">
            <Link onClick={logout}>
              <BoxArrowRight size={21} className="me-2 mb-1" />
              Logout
            </Link>
          </div>
          <div className="about-div">
            <hr />
            <p className="text-muted about-text">
              <b className={'text-' + colorPref}>
                Meal Buddy v{global.config.version}
              </b>
              <br />
              Created by {global.config.author}
              <br />
              {global.config.devDate}
            </p>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Layout;
