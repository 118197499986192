import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { DataContext } from './DataProvider';
import { Table } from 'antd';
import { useAuth } from './AuthContext';

const Lists = () => {
  // console.log('run lists...');
  const navigate = useNavigate();
  const { userPrefs } = useContext(DataContext);
  // const listGroup = lists.map((list) => list.id);

  // var index = listGroup.indexOf('Grocery');
  // if (index > -1) {
  //   listGroup.splice(index, 1);
  // }

  // const listData = listGroup.map((list) => ({
  //   id: list,
  //   note:
  //     list === 'Stores'
  //       ? ''
  //       : '<small className="text-muted">Coming Soon...</small>',
  // }));

  const listData = [
    {
      id: 'Stores',
      note: '',
    },
    {
      id: 'Store Types',
      note: '<small className="text-muted">Coming Soon...</small>',
    },
    {
      id: 'Cocktails',
      note: '<small className="text-muted">Coming Soon...</small>',
    },
    {
      id: 'Restaurants',
      note: '<small className="text-muted">Coming Soon...</small>',
    },
    {
      id: 'Units',
      note: '<small className="text-muted">Coming Soon...</small>',
    },
  ];

  function handleSelectedItem(item) {
    // console.log('item:', item);
    item === 'Stores' && navigate('/lists/stores');
    item === 'Store Types' && navigate('/lists/stores/type');
    // item === 'Cocktails' && navigate('/lists/cocktails');
  }

  const columns = [
    {
      title: 'List Name',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '',
      dataIndex: 'note',
      key: 'note',
      render: (note) => <span dangerouslySetInnerHTML={{ __html: note }} />,
    },
  ];

  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  return (
    <div className="mt-4 mb-5 container-fluid">
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>Lists</div>
        </div>
      </nav>

      <Table
        columns={columns}
        dataSource={listData}
        pagination={false}
        rowKey={(list) => list.id}
        onRow={(list) => {
          return {
            onClick: () => handleSelectedItem(list.id), // click row
          };
        }}
      />
    </div>
  );
};

export default Lists;
