import { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase.config';

console.log('setting auth context...');

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const validEmails = [
    'chris@spycook.com',
    '5pilgrim@spycook.com',
    'cemuir@gmail.com',
  ];

  // Subscribe to auth state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (validEmails.includes(user?.email || '')) {
        console.log('authorized login: ' + user?.email);
        setCurrentUser(user);
      } else {
        console.log('bad user login: ' + user?.email);
        setCurrentUser(null);
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
