import { useContext, useState } from 'react';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';
import Modal from 'react-bootstrap/Modal';
import { Table, Input } from 'antd';
import { PlusSquareFill } from 'react-bootstrap-icons';
import MealForm from './MealForm';

const Meals = () => {
  // console.log('run meals...');

  const { meals, userPrefs } = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    sides: [],
    shoppingList: [],
  });
  const [currentMeal, setCurrentMeal] = useState(null);

  const [filter, setFilter] = useState('');
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));
  const filteredData = sortedMeals.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      title: 'Meal Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const openModal = (meal) => {
    const selectedMeal = meals.find((i) => i.name === meal);
    setCurrentMeal(selectedMeal);
    // console.log('meal:', JSON.stringify(selectedMeal));
    setFormData(selectedMeal || { name: '', sides: [], shoppingList: [] });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentMeal(null);
  };

  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  return (
    <div className="mt-4 mb-5 container-fluid">
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>Meals</div>
          <div className="navbar-nav">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              style={{ fontSize: '16px' }}
            />
          </div>
          <div className={'navbar-nav ml-auto text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
            />
          </div>
        </div>
      </nav>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        rowKey={(meal) => meal.name}
        onRow={(meal) => {
          return {
            onClick: () => openModal(meal.name), // click row
          };
        }}
      />

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            {currentMeal ? 'Edit Meal' : 'Add Meal'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MealForm
            initialData={formData}
            closeModal={closeModal}
            colorPref={colorPref}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Meals;
