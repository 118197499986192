import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from './components/Login'; // login page
import { useAuth } from './components/AuthContext';
import { DataProvider, DataContext } from './components/DataProvider';
import Navbar from './components/Navbar';
import Menus from './components/Menus';
import Meals from './components/Meals';
import Lists from './components/Lists';
// import CocktailList from './components/ListCocktails';
// import AddCocktail from './components/AddCocktail';
import JsonInputForm from './components/JsonInputForm';
import Groceries from './components/Groceries';
import Items from './components/Items';
import Settings from './components/Settings';
import ScrollToTop from './components/scrollToTop';
import Stores from './components/Stores';
import StoreTypes from './components/StoreTypes';
import { useContext } from 'react';
import Shop from './components/Shop';
import History from './components/History';

export default function App() {
  // console.log('run app...');

  const { currentUser } = useAuth();
  if (!currentUser) {
    return <Login />;
  }
  return (
    <BrowserRouter>
      <DataProvider>
        <ScrollToTop />
        <DataConsumer />
      </DataProvider>
    </BrowserRouter>
  );
}

function DataConsumer() {
  const { loading } = useContext(DataContext);

  if (loading) {
    return <div></div>; // or a more sophisticated loading spinner
  }

  return (
    <Routes>
      <Route path="/" element={<Navbar />}>
        <Route index element={<Menus />} />
        <Route path="meals" element={<Meals />} />
        <Route path="items" element={<Items />} />
        <Route path="shop" element={<Shop />} />
        <Route path="groceries" element={<Groceries />} />
        <Route path="lists" element={<Lists />} />
        <Route path="lists/stores" element={<Stores />} />
        <Route path="lists/stores/type" element={<StoreTypes />} />
        {/* <Route path="lists/cocktails" element={<CocktailList />} /> */}
        {/* <Route path="lists/cocktails/add" element={<AddCocktail />} /> */}
        <Route path="data/input" element={<JsonInputForm />} />
        <Route path="settings" element={<Settings />} />
        <Route path="history" element={<History />} />
        <Route path="*" element={<Menus />} />
      </Route>
    </Routes>
  );
}
