import { Table, Input } from 'antd';
import { useContext, useState } from 'react';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';

const History = () => {
  const { userPrefs } = useContext(DataContext);
  const [filter, setFilter] = useState('');
  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <div className="mt-4 mb-5 container-fluid">
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>History</div>
          <div className="navbar-nav d-none">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              style={{ fontSize: '16px' }}
            />
          </div>
          <div className="mt-3">
            <img
              src="/Waback1.jpg"
              alt="Wayback"
              width="50%"
              align="right"
              // className="m-auto d-block mt-2 mb-2"
            />
            <p className="d-block pr-2">
              The Wayback Machine is still being fine tuned. Check back in a
              later version to see what you've been up to.
            </p>
          </div>
          <div className="w-100 mt-2">
            <p>Meanwhile, enjoy this random cat GIF...</p>
            <img
              src="https://cataas.com/cat/gif"
              alt="Cat"
              width="300"
              className="cat m-auto d-block mt-2 mb-2"
            />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default History;
