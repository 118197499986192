import { useState } from 'react';
import { collection, doc, addDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase.config';

const JsonInputForm = () => {
  // console.log('run JsonInputForm...');
  const [jsonInput, setJsonInput] = useState('');

  const handleInputChange = (e) => {
    setJsonInput(e.target.value);
  };

  const handleStoreSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (typeof parsedData.name === 'string') {
        const storeRef = doc(db, 'stores', parsedData.name);
        const { name, ...rest } = parsedData;
        await setDoc(storeRef, rest);
        alert('Document successfully written!');
      } else {
        alert(
          'Invalid JSON format. Ensure it has "name" as a string and "aisles" as a list of objects with "name" and "num" fields.'
        );
      }
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  const handleMealSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (typeof parsedData.name === 'string') {
        await addDoc(collection(db, 'meals'), parsedData);
        alert('Document successfully written!');
      } else {
        alert('Invalid JSON format. Ensure it has "name" as a string.');
      }
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  const handleMealsSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData.mealsList)) {
        const promises = parsedData.mealsList.map(async (meal) => {
          await addDoc(collection(db, 'meals'), meal);
          alert('Documents successfully written!');
          // const mealRef = doc(db, 'meals', meal.name);
          // const { name, ...rest } = meal;
          // await setDoc(mealRef, rest);
        });

        await Promise.all(promises); // await all promises
      } else {
        alert('Invalid JSON format. Ensure it has "mealsList" as an array.');
      }
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  const handleDinnerMenuSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      const dinnerDocRef = doc(db, 'menus', 'dinner');
      await setDoc(dinnerDocRef, parsedData);
      alert('Document successfully written!');
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  const handleItemsSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);

      if (Array.isArray(parsedData.itemsList)) {
        const promises = parsedData.itemsList.map(async (item) => {
          await addDoc(collection(db, 'items'), item);
        });

        await Promise.all(promises); // await all promises
        alert('Documents successfully written!');
      } else {
        alert('Invalid JSON format. Ensure it has "itemsList" as an array.');
      }
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  const handleUnitsSubmit = async () => {
    try {
      const parsedData = JSON.parse(jsonInput);
      if (Array.isArray(parsedData)) {
        const unitsRef = doc(db, 'lists', 'Units');
        await setDoc(unitsRef, { units: parsedData });
        alert('Document successfully written!');
      } else {
        alert('Invalid JSON format. Ensure it is a list of strings.');
      }
    } catch (error) {
      alert('Error parsing JSON or writing document: ' + error.message);
    }
  };

  return (
    <div className="mt-4 container-fluid">
      <h1 className="pb-1 text-center">Bulk Upload</h1>
      <textarea
        className="form-control"
        rows="15"
        // cols="50"
        // placeholder='{"name": "storeName", "aisles": [{"name": "aisle1", "num": 1}, {"name": "aisle2", "num": 2}]}'
        value={jsonInput}
        onChange={handleInputChange}
      />
      <button
        className="btn btn-secondary btn-sm m-1 w-100"
        onClick={handleStoreSubmit}
      >
        Submit Store Data
      </button>
      <div className="btn-group w-100 m-1">
        <button className="btn btn-secondary btn-sm" onClick={handleMealSubmit}>
          Submit Single Meal Data
        </button>
        <button
          className="btn btn-secondary btn-sm"
          onClick={handleMealsSubmit}
        >
          Submit Meals Data
        </button>
      </div>

      <button
        className="btn btn-secondary btn-sm m-1 w-100"
        onClick={handleDinnerMenuSubmit}
      >
        Submit Dinner Menu Data
      </button>

      <button
        className="btn btn-secondary btn-sm m-1 w-100"
        onClick={handleItemsSubmit}
      >
        Submit Items Data
      </button>

      <button
        className="btn btn-secondary btn-sm m-1 w-100"
        onClick={handleUnitsSubmit}
      >
        Submit Units Data
      </button>
    </div>
  );
};

export default JsonInputForm;
