import { useState, useEffect } from 'react';
import { auth, googleProvider } from '../firebase.config';
import { signInWithPopup } from 'firebase/auth';

export const Login = () => {
  // console.log('run login...');
  console.log('email: ' + auth?.currentUser?.email);

  const [loading, setLoading] = useState(false);

  const signInWithGoogle = async () => {
    // console.log('sign in with google...');
    setLoading(true); // Set loading to true when sign-in starts
    try {
      await signInWithPopup(auth, googleProvider);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    // This will ensure loading is false if the component is loaded and there's no user
    if (!auth.currentUser) {
      setLoading(false);
    }
  }, []);

  if (loading)
    return (
      <div className="text-center mt-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Logging in...</span>
        </div>
      </div>
    );

  return (
    <div className="text-center mt-4">
      <main className="form-signin w-100 m-auto">
        <h1>Meal Buddy</h1>
        <form className="mx-4">
          <img
            className="mb-4"
            src="/buddy-logo.png"
            alt=""
            width="200"
            height="200"
          />
          <h1 className="h3 mb-3 fw-normal">Please log in...</h1>
          <button
            type="button"
            className="btn btn-primary w-100 py-2"
            onClick={signInWithGoogle}
          >
            Sign In With Google
          </button>
          <p className="mt-5 mb-3 text-body-secondary">©2024 Cookware™</p>
        </form>
      </main>
    </div>
  );
};
