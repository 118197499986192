import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { DataContext } from './DataProvider';
import Modal from 'react-bootstrap/Modal';

const ListManager = ({ listType, shoppingMode, currentUser }) => {
  // Use context to get data and methods from DataContext
  const {
    items,
    lists,
    addListItem, // Generic function for adding items to a list
    bulkAddListItems, // Generic function for adding multiple items to a list
    updateListItem, // Generic function for updating an item on a list
    removeListItem, // Generic function for removing items from a list
    getItemByName,
    updateItem,
    menus,
    meals,
    userPrefs,
    updateUserPrefs,
    updateShoppingHistory,
    updateStoreSortOrder,
  } = useContext(DataContext);

  const [selectedStore, setSelectedStore] = useState(null);
  const [hasAisles, setHasAisles] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [storeModalIsOpen, setStoreModalIsOpen] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const [aisleModalIsOpen, setAisleModalIsOpen] = useState(false);

  // form data when editing an item on the shopping list
  const [formData, setFormData] = useState({
    name: '',
    origName: '',
    locations: [],
    origLocations: [],
  });

  // sort order preference for items on the shopping lists
  const [sortOrder, setSortOrder] = useState({
    columnKey: 'aisle',
    order: 'ascend',
  });

  useEffect(() => {
    const storePref =
      userPrefs?.[currentUser?.uid]?.['default_' + listType.toLowerCase()];
    const storeList = lists.find((list) => list.id === 'Stores');
    const firstAvailableStore = storeList.items.find(
      (store) => store.type === listType
    )?.name;

    const initialStore =
      storePref &&
      storeList.items.find((store) => store.name === storePref)?.type ===
        listType
        ? storePref
        : firstAvailableStore;

    setSelectedStore({
      value: initialStore,
      label: initialStore,
    });

    const savedSortOrder = storeList.items.find(
      (store) => store.name === initialStore
    )?.sortOrder;
    if (savedSortOrder) {
      setSortOrder(savedSortOrder);
    }

    setHasAisles(
      storeList.items.find((store) => store.name === initialStore)?.aisles
        ?.length > 0
    );
  }, [listType, currentUser?.uid, userPrefs, lists]);

  // Find the relevant list from lists based on listType
  const currentList = lists.find((list) => list.id === listType);

  // List of stores with the specified listType
  const stores = lists.find((list) => list.id === 'Stores');
  const filteredStores = stores?.items
    ? [...stores.items].filter((store) => store.type === listType)
    : [];

  // Sort items and meals by name
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));

  // Handle adding a single list item
  const handleAddListItem = (item) => {
    addListItem(listType, item);
    setSelectedItem(null);
  };

  // Handle adding a meal's grocery items (if applicable to the listType)
  const handleAddMealItems = (meal) => {
    bulkAddListItems(listType, meal.shoppingList);
    setSelectedMeal(null);
  };

  // Handle selecting an item on the list. Edit if not in shopping mode.
  const handleSelectItem = (item) => {
    const storeHasAisles =
      stores.items.find((store) => store.name === selectedStore?.value)?.aisles
        .length > 0;
    const itemHasAisle = item.locations
      .find((location) => location.store === selectedStore?.value)
      ?.aisle.toString();

    if (shoppingMode) {
      if (storeHasAisles && !itemHasAisle) {
        // Offer to update the aisle when shopping
        openAisleModal(item);
      } else {
        handleRemoveListItem(item.name);
      }
    } else {
      openItemModal(item);
    }
  };

  // Handle removing a list item
  const handleRemoveListItem = (item) => {
    if (shoppingMode) {
      const updatedItem = {
        item: item,
        store: selectedStore?.value,
        date: new Date().toLocaleDateString('en-US'),
      };
      updateShoppingHistory(updatedItem);
    }
    removeListItem(listType, item);
  };

  // Handle removing a list item from the item modal
  const handleRemoveListItemByModal = (item) => {
    handleRemoveListItem(item);
    closeItemModal();
  };

  // Handle removing a list item from the aisle modal
  const handleRemoveListItemByAisleModal = (item) => {
    handleRemoveListItem(item);
    closeAisleModal();
  };

  // Prepare store options for the select dropdown
  const storeOptions = filteredStores.map((store) => ({
    value: store.name,
    label: store.name,
  }));

  // Open the store modal
  const openStoreModal = () => {
    setStoreModalIsOpen(true);
  };

  // Handle changing the selected store
  const handleStoreChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    updateUserPrefs(currentUser?.uid, {
      [`default_${listType.toLowerCase()}`]: selectedOption.value,
    });
    closeStoreModal();
  };

  // Close the store modal
  const closeStoreModal = () => {
    setStoreModalIsOpen(false);
  };

  // Open the item modal
  const openItemModal = (item) => {
    setSelectedItem(item);
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setItemModalIsOpen(true);
  };

  // Handle changing the item name
  const handleItemNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  // Handle changing the item Aisle #
  const handleItemAisleChange = (e) => {
    const storeExists = formData.locations.some(
      (location) => location.store === selectedStore?.value
    );

    if (storeExists) {
      // Update the aisle of the selectedStore in the formData
      const updatedLocations = formData.locations.map((location) =>
        location.store === selectedStore?.value
          ? { ...location, aisle: Number(e.target.value) }
          : location
      );
      setFormData({ ...formData, locations: updatedLocations });
    } else {
      // Add the store and aisle to the formData
      const newLocation = {
        store: selectedStore?.value,
        aisle: Number(e.target.value),
      };
      const updatedLocations = [...formData.locations, newLocation];
      setFormData({
        ...formData,
        locations: updatedLocations,
      });
    }
  };

  // Handle submitting the item form
  const handleItemSubmit = async (e) => {
    e.preventDefault();
    const { name, origName, locations, origLocations } = formData;
    if (name === origName && locations === origLocations) {
      // Nothing changed
    } else if (name !== origName && locations === origLocations) {
      // Only the name changed
      updateListItem(listType, name, origName, locations);
    } else if (name === origName && locations !== origLocations) {
      // Only the aisle changed
      itemAisleUpdater();
    } else if (name !== origName && locations !== origLocations) {
      // Both name and locations changed
      await updateListItem(listType, name, origName, locations);
      itemAisleUpdater();
    }
    setItemModalIsOpen(false);

    function itemAisleUpdater() {
      let updatedItem = getItemByName(origName);
      updateItem({ ...updatedItem, locations: locations });
      updateListItem(listType, name, origName, locations);
    }
  };

  // Close the item modal
  const closeItemModal = () => {
    setItemModalIsOpen(false);
    setSelectedItem(null);
  };

  // Open the aisle modal
  const openAisleModal = (item) => {
    setSelectedItem(item);
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setAisleModalIsOpen(true);
  };

  // Close the aisle modal
  const closeAisleModal = () => {
    setAisleModalIsOpen(false);
    setSelectedItem(null);
  };

  // Handle submitting the aisle form
  const handleAisleSubmit = async (e) => {
    e.preventDefault();
    const { origName, locations, origLocations } = formData;
    if (locations !== origLocations) {
      // The aisle changed
      let updatedItem = getItemByName(origName);
      updateItem({ ...updatedItem, locations: locations });
    }
    await handleRemoveListItemByAisleModal(selectedItem.name);
    setAisleModalIsOpen(false);
  };

  // Add this week's shopping list to the current list
  const addThisWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu?.meals?.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum < 8 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    return bulkAddListItems(listType, newShoppingList);
  };

  // Add next week's shopping list to the current list
  const addNextWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu.meals.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum > 7 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    return bulkAddListItems(listType, newShoppingList);
  };

  // Handle sort change
  const handleSort = async (columnKey) => {
    const order =
      sortOrder.columnKey === columnKey && sortOrder.order === 'ascend'
        ? 'descend'
        : 'ascend';
    setSortOrder({ columnKey, order });

    const updatedData = {
      storeName: selectedStore?.value,
      sortOrder: { columnKey, order },
    };
    await updateStoreSortOrder(updatedData);
  };

  // Define columns for the table
  const col1 = {
    title: 'Aisle',
    dataIndex: 'aisle',
    width: '10%',
    align: 'center',
    sortOrder: sortOrder.columnKey === 'aisle' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('aisle'),
    }),
    sorter: (a, b) => a.aisle - b.aisle,
  };

  const col2 = {
    title: 'Item Name',
    dataIndex: 'name',
    sortOrder: sortOrder.columnKey === 'name' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('name'),
    }),
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const tableColumns = hasAisles ? [col1, col2] : [col2];

  // Prepare data for the table
  const tableData = currentList?.items?.map((item) => ({
    item: item,
    name: item.name,
    aisle:
      item.locations.length > 0 &&
      item.locations.find((location) => location.store === selectedStore?.value)
        ? item.locations.find(
            (location) => location.store === selectedStore.value
          ).aisle
        : '',
  }));

  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  if (!selectedStore) {
    return <div></div>; // or a loading indicator
  }

  return (
    <div className="mb-5 container-fluid">
      <nav className="navbar shopper-navbar">
        <div className="p-0 container">
          <div
            onClick={openStoreModal}
            className={'h4 pb-0 mb-0 text-' + colorPref}
          >
            {selectedStore.label}
          </div>
        </div>
      </nav>

      {shoppingMode ? (
        <div></div>
      ) : (
        <div className="mb-3" id={'addItemPickerGroup-' + listType}>
          <InputGroup className="mb-1">
            <InputGroup.Text className="groceryPicker" style={{ width: '38%' }}>
              Add Single Item
            </InputGroup.Text>
            <Select
              className="flex-grow-top"
              id="addItemPicker"
              value={selectedItem}
              onChange={(picker) => handleAddListItem(picker.value)}
              options={sortedItems.map((item) => ({
                value: item,
                label: item.name,
              }))}
              placeholder="Select an item"
            />
          </InputGroup>
          {/* Conditionally render meal picker if listType is Grocery */}
          {(listType === 'Grocery' || listType === 'Wholesale') && (
            <InputGroup className="mb-1">
              <InputGroup.Text
                className="groceryPicker"
                style={{ width: '38%' }}
              >
                Add Meal Items
              </InputGroup.Text>
              <Select
                className="flex-grow-bottom"
                id="addMealPicker"
                value={selectedMeal}
                onChange={(picker) => handleAddMealItems(picker.value)}
                options={sortedMeals.map((meal) => ({
                  value: meal,
                  label: meal.name,
                }))}
                placeholder="Select a meal"
              />
            </InputGroup>
          )}
          {/* Conditionally render menu buttons if listType is Grocery */}
          {(listType === 'Grocery' || listType === 'Wholesale') && (
            <ButtonGroup className="w-100 gap-1">
              <Button
                id="addThisWeeksShoppingList-button"
                variant="secondary"
                className="addMenu-button"
                onClick={() => addThisWeeksShoppingList()}
              >
                Add <b>This Week's</b> Menu
              </Button>
              <Button
                id="addNextWeeksShoppingList-button"
                className="addMenu-button"
                variant={colorPref}
                onClick={() => addNextWeeksShoppingList()}
              >
                Add <b>Next Week's</b> Menu
              </Button>
            </ButtonGroup>
          )}
        </div>
      )}

      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        rowKey={(r) => r.name}
        onRow={(r) => {
          return {
            onClick: () => handleSelectItem(r.item),
          };
        }}
      />

      {/* Store Picker Modal */}
      <Modal
        show={storeModalIsOpen}
        onHide={closeStoreModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            Select a {listType} Store
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            className="mb-3"
            value={selectedStore}
            onChange={handleStoreChange}
            options={storeOptions}
            placeholder={`Select a ${listType.toLowerCase()} store`}
          />
        </Modal.Body>
      </Modal>

      {/* Item Edit Modal */}
      <Modal
        show={itemModalIsOpen}
        onHide={closeItemModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleItemSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Edit Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleItemNameChange}
                required
              />
            </Form.Group>
            {/* Aisle Picker (if applicable) */}
            {stores.items.find((store) => store.name === selectedStore?.value)
              ?.aisles.length > 0 ? (
              <>
                <Form.Label>Aisle @ {selectedStore?.value}:</Form.Label>
                <InputGroup className="mb-1 ig-stores">
                  <Form.Select
                    name={selectedStore?.value}
                    value={
                      formData.locations
                        .find(
                          (location) => location.store === selectedStore?.value
                        )
                        ?.aisle.toString() || ''
                    }
                    onChange={handleItemAisleChange}
                  >
                    <option value="" disabled></option>
                    {stores.items
                      .find((store) => store.name === selectedStore?.value)
                      .aisles.sort((a, b) => a.num - b.num)
                      .map((aisle) => (
                        <option key={aisle.name} value={aisle.num}>
                          {aisle.num + ' ' + aisle.name}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </>
            ) : (
              <div>
                No aisles defined for{' '}
                <Link to="/lists/stores">{selectedStore?.value}</Link> 🙄
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              <Button
                variant={colorPref === 'danger' ? 'warning' : 'danger'}
                className="mr-2"
                onClick={() => handleRemoveListItemByModal(selectedItem.name)}
              >
                Remove
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeItemModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* Aisle Picker Modal */}
      <Modal
        show={aisleModalIsOpen}
        onHide={closeAisleModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleAisleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Add Aisle</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mb-3">
              <small>
                Since you're adding{' '}
                <b className={'text-' + colorPref}>{selectedItem?.name}</b> to
                your cart, why not add the aisle number so it's easier to find
                next time.
              </small>
            </div>
            <Form.Label>Aisle @ {selectedStore?.value}:</Form.Label>
            <InputGroup className="mb-1 ig-stores">
              <Form.Select
                name={selectedStore?.value}
                value={
                  formData.locations
                    .find((location) => location.store === selectedStore?.value)
                    ?.aisle.toString() || ''
                }
                onChange={handleItemAisleChange}
              >
                <option value="" disabled></option>
                {stores.items
                  .find((store) => store.name === selectedStore?.value)
                  .aisles.sort((a, b) => a.num - b.num)
                  .map((aisle) => (
                    <option key={aisle.name} value={aisle.num}>
                      {aisle.num + ' ' + aisle.name}
                    </option>
                  ))}
              </Form.Select>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Update
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={() =>
                  handleRemoveListItemByAisleModal(selectedItem?.name)
                }
              >
                Not Now
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default ListManager;
