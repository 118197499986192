// example:
// https://codesandbox.io/s/framer-motion-5-drag-to-reorder-lists-uonye?from-embed=&file=/src/Item.tsx
import { useState } from 'react';
import { Reorder } from 'framer-motion';
// import { arrayMoveImmutable } from 'array-move';

// Sample JSON data
const initialItems = ['🍅 Tomato', '🥒 Cucumber', '🧀 Cheese', '🥬 Lettuce'];

const DraggableList = ({ items = initialItems }) => {
  const [currentItems, setCurrentItems] = useState(items);

  return (
    <div>
      <Reorder.Group values={currentItems} onReorder={setCurrentItems}>
        {currentItems.map((item, index) => (
          <Reorder.Item key={index} value={item} index={item}>
            {item}
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </div>
  );
};

export default DraggableList;
