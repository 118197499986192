import { useContext, useState } from 'react';
import { XLg, Plus, PlusSquareFill } from 'react-bootstrap-icons';
import { Table, Input } from 'antd';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

const Stores = () => {
  const {
    lists,
    updateStore,
    addStore,
    removeStore,
    findItemsContainingStore,
    userPrefs,
  } = useContext(DataContext);
  const stores = lists.find((list) => list.id === 'Stores');
  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', type: '', aisles: [] });
  const [currentStore, setCurrentStore] = useState(null);
  const [itemsWithStore, setItemsWithStore] = useState([]);
  const [itemsListIsVisible, setItemsListIsVisible] = useState(false);
  const [filter, setFilter] = useState('');
  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  // Check if stores is defined and has items
  const sortedStores = stores?.items
    ? [...stores.items].sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const filteredData = sortedStores.filter((store) =>
    store.name.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      title: 'Store Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
  ];

  const openModal = (store) => {
    const selectedStore = stores?.items?.find((s) => s.name === store);
    setItemsWithStore(findItemsContainingStore(store));
    setCurrentStore(selectedStore);
    setFormData(selectedStore || { name: '', type: '', aisles: [] });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentStore(null);
  };

  const handleAddAisle = () => {
    setFormData({
      ...formData,
      aisles: [
        ...formData.aisles,
        { num: formData.aisles.length + 1, name: '' },
      ],
    });
  };

  const handleRemoveAisle = (index) => {
    const newAisles = formData.aisles.filter((_, i) => i !== index);
    setFormData({ ...formData, aisles: newAisles });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log('name:', name, 'value:', value, 'formData:', formData);

    if (name.startsWith('aisle-')) {
      const [field, index] = name.split('-').slice(1);
      const newAisles = formData.aisles.map((aisle, i) =>
        i === parseInt(index) ? { ...aisle, [field]: value } : aisle
      );
      setFormData({ ...formData, aisles: newAisles });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStore) {
      updateStore(currentStore.name, formData);
    } else {
      addStore(formData);
    }
    closeModal();
  };

  const handleRemove = async () => {
    await removeStore(currentStore.name);
    closeModal();
  };

  const toggleVisibility = () => {
    setItemsListIsVisible(!itemsListIsVisible);
  };

  return (
    <div className="mt-4 mb-5 container-fluid">
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>Stores</div>
          <div className="navbar-nav">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              style={{ fontSize: '16px' }}
            />
          </div>
          <div className={'navbar-nav ml-auto text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
            />
          </div>
        </div>
      </nav>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        rowKey={(store) => store.name}
        onRow={(store) => {
          return {
            onClick: () => openModal(store.name), // click row
          };
        }}
      />

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>
              {currentStore ? 'Edit Store' : 'Add Store'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name: *</Form.Label>
              <Form.Control
                type="text"
                size="lg"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Type: *</Form.Label>
              <Form.Select
                name="type"
                value={formData.type}
                onChange={handleChange}
                required
              >
                <option value="" disabled>
                  Select a store type
                </option>
                {stores?.types?.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Label>Aisles:</Form.Label>
            <div className="row">
              <div
                className="col-2 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted ">&nbsp;#</small>
              </div>
              <div
                className="col-10 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted">Name</small>
              </div>
            </div>
            {formData.aisles
              // .sort((a, b) => a.num - b.num)
              .map((aisle, index) => (
                <div key={index}>
                  <InputGroup className="mb-1 flex-container store-aisle">
                    <Form.Control
                      className="aisle-input"
                      type="text"
                      name={`aisle-num-${index}`}
                      value={aisle.num}
                      onChange={handleChange}
                    />
                    <Form.Control
                      className="name-input"
                      type="text"
                      name={`aisle-name-${index}`}
                      value={aisle.name}
                      onChange={handleChange}
                    />
                    <Button
                      variant="outline-secondary"
                      // size="sm"
                      onClick={() => handleRemoveAisle(index)}
                    >
                      <XLg size={12} className="mb-1" />
                    </Button>
                  </InputGroup>
                </div>
              ))}
            <div className="d-grid gap-2">
              <Button variant={colorPref} onClick={handleAddAisle}>
                <Plus size={20} />
                Add Aisle
              </Button>
            </div>
            <Form.Label className="mt-3">
              {itemsWithStore.length} Items using this store:
            </Form.Label>
            <div onClick={toggleVisibility}>Show/Hide</div>
            <div
              className={`item-with-store ${
                itemsListIsVisible ? '' : 'd-none'
              }`}
            >
              <ul className="store-item-list">
                {itemsWithStore.length > 0 ? (
                  itemsWithStore
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((item) => <li key={item.id}>{item.name}</li>)
                ) : (
                  <li className="store-item-list-store">
                    <i>Sorry, no items are associated with this store</i> 😥
                  </li>
                )}
              </ul>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              {currentStore && !itemsWithStore.length > 0 && (
                <Button
                  variant={colorPref === 'danger' ? 'warning' : 'danger'}
                  className="mr-2"
                  onClick={handleRemove}
                >
                  Delete
                </Button>
              )}
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Stores;
