import { Tabs, Switch } from 'antd';
import { useContext, useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';
import ListManager from './ListManager';
import Alert from 'react-bootstrap/Alert';
import { List } from 'react-bootstrap-icons';

const Shop = () => {
  const { currentUser } = useAuth();
  const { storeTypes, userPrefs, updateUserPrefs } = useContext(DataContext);
  const [shoppingMode, setShoppingMode] = useState(false);
  const [activeList, setActiveList] = useState(storeTypes[0]);
  const [showShoppingModeMessage, setShowShoppingModeMessage] = useState(false);

  useEffect(() => {
    if (userPrefs?.[currentUser?.uid]?.active_list) {
      setActiveList(userPrefs?.[currentUser?.uid]?.active_list);
    }
  }, [userPrefs, currentUser?.uid]);

  const items = storeTypes.map((type) => {
    return {
      label: type,
      key: type,
      children: (
        <ListManager
          listType={type}
          shoppingMode={shoppingMode}
          currentUser={currentUser}
        />
      ),
    };
  });

  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  const toggleShoppingMode = (on) => {
    let body = document.getElementsByTagName('body')[0];
    let navbarTop = document.getElementById('navbar-top');
    let navbarBottom = document.getElementById('navbar-bottom');
    let navbarShop = document.getElementById('navbar-shop');

    if (on) {
      if (!userPrefs?.[currentUser?.uid]?.seenShoppingModeMessage) {
        setShowShoppingModeMessage(true);
        updateUserPrefs(currentUser?.uid, {
          seenShoppingModeMessage: true,
        });
      }
      setShoppingMode(true);
      navbarTop.style.display = 'none';
      navbarBottom.style.display = 'none';
      navbarShop.style.display = 'block';
      body.style.paddingTop = '1.7rem';
      body.style.paddingBottom = '0';
    } else {
      setShoppingMode(false);
      navbarTop.style.display = 'block';
      navbarBottom.style.display = 'block';
      navbarShop.style.display = 'none';
      body.style.paddingTop = '3rem';
      body.style.paddingBottom = '3rem';
    }
  };

  const shopSwitch = (
    <span>
      &nbsp; &nbsp;
      <Switch
        checkedChildren="Shop"
        unCheckedChildren="Edit"
        onChange={(e) => {
          toggleShoppingMode(e);
        }}
      />
    </span>
  );

  const setActiveListPref = (list) => {
    updateUserPrefs(currentUser?.uid, {
      active_list: list,
    });
  };

  return (
    <div className="pt-1 container-fluid">
      {showShoppingModeMessage && (
        <Alert variant={colorPref} dismissible>
          <Alert.Heading>
            <strong className="me-auto">Welcome to Shopping Mode!</strong>
          </Alert.Heading>
          While in Shopping Mode, items will be removed from your list and added
          to your shopping history.
          <br />
          <br />
          <small>
            <i>
              Note: Your shopping and meal history can be viewed from the
              Options <List /> menu.
            </i>
          </small>
        </Alert>
      )}

      <Tabs
        id="shop-tabs"
        tabBarExtraContent={shopSwitch}
        items={items}
        activeKey={activeList}
        onTabClick={setActiveListPref}
      />
    </div>
  );
};

export default Shop;
