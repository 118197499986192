import { useContext } from 'react';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';

const Settings = () => {
  const { currentUser } = useAuth();
  const { userPrefs, updateUserPrefs } = useContext(DataContext);

  const setColorPref = (color) => {
    updateUserPrefs(currentUser?.uid, {
      color_pref: color,
    });
  };

  const handleColorClick = (e) => {
    // console.log('val:', e.target.className.split('-')[1]);
    setColorPref(e.target.className.split('-')[1]);
  };

  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  const isPrimary = colorPref === 'primary' ? true : false;
  const isInfo = colorPref === 'info' ? true : false;
  const isSuccess = colorPref === 'success' ? true : false;
  const isDanger = colorPref === 'danger' ? true : false;
  const isWarning = colorPref === 'warning' ? true : false;
  const isSecondary = colorPref === 'secondary' ? true : false;
  const isDark = colorPref === 'dark' ? true : false;

  return (
    <div className="mt-4 mb-5 container-fluid">
      <img
        align="right"
        src={currentUser?.photoURL}
        className="avatar"
        alt="avatar"
      />
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>Settings</div>
        </div>
      </nav>

      <div className="container lh-lg">
        <b>Name:</b> {currentUser?.displayName}
        <br />
        <b>Email:</b> {userPrefs?.[currentUser?.uid]?.email}
        <br />
      </div>

      <div className="container pt-1">
        <b>Theme Color:</b>
        <br />
        <div className="mt-1 d-flex justify-content-between text-center align-items-center">
          <span className="dot bg-primary" onClick={handleColorClick}></span>
          <span className="dot bg-info" onClick={handleColorClick}></span>
          <span className="dot bg-success" onClick={handleColorClick}></span>
          <span className="dot bg-danger" onClick={handleColorClick}></span>
          <span className="dot bg-warning" onClick={handleColorClick}></span>
          <span className="dot bg-secondary" onClick={handleColorClick}></span>
          <span className="dot bg-dark" onClick={handleColorClick}></span>
        </div>
        <div className="mt-1 d-flex justify-content-between text-center align-items-center">
          <span className="dotSelect text-primary">{isPrimary && '•'}</span>
          <span className="dotSelect text-info">{isInfo && '•'}</span>
          <span className="dotSelect text-success">{isSuccess && '•'}</span>
          <span className="dotSelect text-danger">{isDanger && '•'}</span>
          <span className="dotSelect text-warning">{isWarning && '•'}</span>
          <span className="dotSelect text-secondary">{isSecondary && '•'}</span>
          <span className="dotSelect text-dark">{isDark && '•'}</span>
        </div>
      </div>
    </div>
  );
};

export default Settings;
