import { useState, useContext } from 'react';
import { DataContext } from './DataProvider';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { XLg, Plus } from 'react-bootstrap-icons';
import Select from 'react-select';

const MealForm = ({ initialData, closeModal, colorPref }) => {
  const [name, setName] = useState(initialData?.name || '');
  const [sides, setSides] = useState(initialData?.sides || []);
  const [shoppingList, setShoppingList] = useState(
    initialData?.shoppingList || []
  );
  // console.log('initial shoppingList:', initialData?.shoppingList);

  const { items, updateMeal, addMeal, deleteMeal } = useContext(DataContext);
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));

  // Transform the sortedItems to the format react-select expects
  const options = sortedItems.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const handleAddItem = () => {
    setShoppingList([...shoppingList, { name: '', locations: [] }]);
  };

  const handleRemoveItem = (index) => {
    setShoppingList(shoppingList.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, selectedOption) => {
    const itemName = selectedOption.value;
    const locations = items.find((i) => i.name === itemName).locations;
    const newShoppingList = shoppingList.map((item, i) =>
      i === index ? { ...item, name: itemName, locations: locations } : item
    );
    // console.log('newShoppingList:', newShoppingList);
    setShoppingList(newShoppingList);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docData = { name, sides, shoppingList };
    // console.log('docData:', docData);
    if (initialData?.name) {
      const updateData = { ...docData, id: initialData.id };
      // console.log('updateData:', updateData);
      await updateMeal(updateData);
      closeModal();
    } else {
      // console.log('docData:', docData);
      await addMeal(docData);
      closeModal();
    }
  };

  const handleRemove = async () => {
    await deleteMeal(initialData.id);
    closeModal();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-2">
        <Form.Control
          size="lg"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <Form.Control
          type="text"
          placeholder="Sides"
          value={sides.join(', ')}
          onChange={(e) => setSides(e.target.value.split(', '))}
        />
      </div>
      <div>
        <label className="form-label">Shopping List:</label>
        {shoppingList.map((item, index) => (
          <div key={index}>
            <InputGroup className="mb-1 flex-container">
              <Select
                className="flex-grow-1"
                value={
                  item.name
                    ? options.find((option) => option.value === item.name) || {
                        value: item.name,
                        label: item.name,
                      }
                    : null
                }
                onChange={(selectedOption) =>
                  handleInputChange(index, selectedOption)
                }
                options={options}
                placeholder="Select an item"
              />
              <Button
                variant="outline-secondary"
                onClick={() => handleRemoveItem(index)}
              >
                <XLg size={12} />
              </Button>
            </InputGroup>
          </div>
        ))}
        <div className="d-grid gap-2">
          <Button variant={colorPref} onClick={handleAddItem}>
            <Plus size={20} />
            Add Item
          </Button>
        </div>
        <div className="mt-3 d-grid gap-2 d-flex justify-content-end modal-buttons">
          <Button variant={colorPref} type="submit">
            Save
          </Button>
          {initialData && (
            <Button
              variant={colorPref === 'danger' ? 'warning' : 'danger'}
              className="mr-2"
              onClick={handleRemove}
            >
              Delete
            </Button>
          )}
          <Button
            variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

export default MealForm;
