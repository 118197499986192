import { useContext, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { DataContext } from './DataProvider';
import Button from 'react-bootstrap/Button';
import { Table, Input } from 'antd';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { PlusSquareFill } from 'react-bootstrap-icons';

const Items = () => {
  // console.log('run Items...');

  const {
    items,
    lists,
    addItem,
    updateItem,
    deleteItem,
    findMealsContainingItem,
    userPrefs,
  } = useContext(DataContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    locations: [],
  });
  const [currentItem, setCurrentItem] = useState(null);
  const [mealsWithItem, setMealsWithItem] = useState([]);
  const [filter, setFilter] = useState('');

  const stores = lists.find((list) => list.id === 'Stores');

  const handleInputChange = (e) => {
    setFilter(e.target.value);
  };

  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const filteredData = sortedItems.filter((item) =>
    item.name.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
    },
  ];

  const openModal = (item) => {
    const selectedItem = items.find((i) => i.name === item);
    setMealsWithItem(findMealsContainingItem(item));
    setCurrentItem(selectedItem);
    setFormData(selectedItem || { name: '', locations: [] });
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log('name:', name, 'value:', value, 'formData:', formData);
    if (name.indexOf('::') > -1) {
      const storeId = name.split('::')[1];
      const storeData = [
        ...formData.locations.map((location) =>
          location.store === storeId
            ? { ...location, aisle: Number(value) }
            : location
        ),
        ...(formData.locations.some((location) => location.store === storeId)
          ? []
          : [{ store: storeId, aisle: Number(value) }]),
      ];
      // console.log('storeData:', { storeData });
      setFormData({ ...formData, locations: storeData });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentItem) {
      updateItem(formData);
    } else {
      addItem(formData);
    }
    closeModal();
  };

  const handleRemove = async () => {
    deleteItem(formData);
    closeModal();
  };

  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;
  // const navigate = useNavigate();
  // if (!currentUser) {
  //   console.log('access denied to meal list');
  //   navigate('/');
  // }

  return (
    <div className="mt-4 mb-5 container-fluid">
      <nav className="navbar">
        <div className="container mb-2">
          <div className={'h1 pb-0 mb-0 text-' + colorPref}>Items</div>
          <div className="navbar-nav">
            <Input
              placeholder="Filter by name"
              onChange={handleInputChange}
              style={{ fontSize: '16px' }}
            />
          </div>
          <div className={'navbar-nav ml-auto text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => openModal(null)}
              size={32}
            />
          </div>
        </div>
      </nav>

      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        rowKey={(item) => item.name}
        onRow={(item) => {
          return {
            onClick: () => openModal(item.name), // click row
          };
        }}
      />

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>
              {currentItem ? 'Edit Item' : 'Add Item'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Label>Locations:</Form.Label>
            <div className="row">
              <div
                className="col-9 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted">&nbsp;Store Name</small>
              </div>
              <div
                className="col-3 mb-1"
                style={{
                  fontWeight: 'bold',
                  fontSize: '.8em',
                  fontStyle: 'italic',
                }}
              >
                <small className="text-muted">&nbsp;Aisle</small>
              </div>
            </div>
            {stores?.items.map(
              (store) =>
                store.aisles.length > 0 && (
                  <InputGroup key={store.name} className="mb-1 ig-stores">
                    <InputGroup.Text id={store.name}>
                      {store.name}
                    </InputGroup.Text>
                    <Form.Select
                      name={'STORE::' + store.name}
                      value={
                        formData.locations
                          .find((location) => location.store === store.name)
                          ?.aisle.toString() || ''
                      }
                      onChange={handleChange}
                    >
                      <option value="" disabled></option>
                      {store.aisles
                        .sort((a, b) => a.num - b.num) // Sort aisles by aisle number
                        .map((aisle) => (
                          <option key={aisle.name} value={aisle.num}>
                            {aisle.num + ' ' + aisle.name}
                          </option>
                        ))}
                    </Form.Select>
                  </InputGroup>
                )
            )}
            <Form.Label className="mt-3">Meals using this item:</Form.Label>
            <ul className="item-meal-list">
              {mealsWithItem.length > 0 ? (
                mealsWithItem.map((meal) => <li key={meal.id}>{meal.name}</li>)
              ) : (
                <li className="item-meal-list-item">
                  <i>Sorry, no meals with this item</i> 😥
                </li>
              )}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              {currentItem && !mealsWithItem.length > 0 && (
                <Button
                  variant={colorPref === 'danger' ? 'warning' : 'danger'}
                  className="mr-2"
                  onClick={handleRemove}
                >
                  Delete
                </Button>
              )}
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Items;
