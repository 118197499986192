import { createContext, useState, useEffect } from 'react';
import { db, auth } from '../firebase.config';
import {
  collection,
  getDocs,
  onSnapshot,
  doc,
  addDoc,
  setDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

// Create a context
const DataContext = createContext();

const DataProvider = ({ children }) => {
  // console.log('run DataProvider...');

  const [menus, setMenus] = useState([]);
  const [meals, setMeals] = useState([]);
  const [items, setItems] = useState([]);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [userPrefs, setUserPrefs] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const menuCollection = collection(db, 'menus');
        const mealCollection = collection(db, 'meals');
        const itemsCollection = collection(db, 'items');
        const listsCollection = collection(db, 'lists');

        const [menusSnapshot, mealsSnapshot, itemsSnapshot, listsSnapshot] =
          await Promise.all([
            getDocs(menuCollection),
            getDocs(mealCollection),
            getDocs(itemsCollection),
            getDocs(listsCollection),
          ]);

        setMenus(
          menusSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setMeals(
          mealsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setItems(
          itemsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );
        setLists(
          listsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();

    // Set up real-time listeners
    const unsubscribeMenus = onSnapshot(collection(db, 'menus'), (snapshot) => {
      setMenus(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeMeals = onSnapshot(collection(db, 'meals'), (snapshot) => {
      setMeals(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeItems = onSnapshot(collection(db, 'items'), (snapshot) => {
      setItems(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    const unsubscribeLists = onSnapshot(collection(db, 'lists'), (snapshot) => {
      setLists(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeMenus();
      unsubscribeMeals();
      unsubscribeItems();
      unsubscribeLists();
    };
  }, []); // The empty dependency array ensures this runs on mount and unmount

  const settings = lists.find((list) => list.id === 'Settings');
  const stores = lists.find((list) => list.id === 'Stores');
  const storeTypes = [...new Set(stores?.items?.map((store) => store.type))];
  // console.log('storeTypes:', storeTypes);

  const updateMenu = async (data) => {
    // console.log('updateMenu data: ' + JSON.stringify(data));
    try {
      const menuDoc = doc(db, 'menus', 'dinner');
      await updateDoc(menuDoc, { meals: data });
    } catch (error) {
      console.error('Error updating menu: ', error);
    }
  };

  const addMeal = async (data) => {
    // console.log('addMeal:', JSON.stringify(data));
    // remove empty items from the shoppingList
    const cleanedData = {
      ...data,
      shoppingList: data.shoppingList.filter((item) => item.name),
    };
    // ensure no duplicate names in the meals collection
    const existingMealNames = meals.map((meal) => meal.name);
    if (existingMealNames.includes(cleanedData.name)) {
      // console.log('Meal name already exists.');
      alert('Meal NOT added. Meal name already exists.');
      return;
    }
    try {
      await addDoc(collection(db, 'meals'), cleanedData);
    } catch (error) {
      console.error('Error adding meal: ', error);
    }
  };

  const updateMeal = async (data) => {
    // console.log('updateMeal:', JSON.stringify(data));
    // remove empty items from the shoppingList
    const cleanedData = {
      ...data,
      shoppingList: data.shoppingList.filter((item) => item.name),
    };
    const { id, ...rest } = cleanedData;
    // console.log('rest:', JSON.stringify(rest));
    try {
      const mealDoc = doc(db, 'meals', id);
      await updateDoc(mealDoc, rest);
    } catch (error) {
      console.error('Error updating meal: ', error);
    }
  };

  const deleteMeal = async (id) => {
    // console.log('deleteMeal:', id);
    try {
      await deleteDoc(doc(db, 'meals', id));
    } catch (error) {
      console.error('Error deleting meal: ', error);
    }
  };

  const addItem = async (data) => {
    // console.log('addItem:', JSON.stringify(data));
    // ensure no duplicate names in the items collection
    const existingItemNames = items.map((item) => item.name);
    if (existingItemNames.includes(data.name)) {
      // console.log('Item name already exists.');
      alert('Item NOT added. Item name already exists.');
      return;
    }
    try {
      await addDoc(collection(db, 'items'), data);
    } catch (error) {
      console.error('Error adding item: ', error);
    }
  };

  const updateItem = async (data) => {
    // console.log('updateItem:', JSON.stringify(data));
    // get the original item name in case it changed
    const originalItemName = items.find((item) => item.id === data.id).name;
    // console.log('originalItemName:', originalItemName);

    try {
      const { id, ...rest } = data;
      const itemDoc = doc(db, 'items', id);
      await updateDoc(itemDoc, rest);
    } catch (error) {
      console.error('Error updating item: ', error);
    }
    // update this item for the meals where it is referenced
    const mealsWithItem = findMealsContainingItem(originalItemName);
    mealsWithItem.forEach((meal) => {
      // console.log('meal:', meal);
      updateItemInMeal(meal.id, originalItemName, data);
    });
  };

  const getItemByName = (name) => {
    const item = items.find((item) => item.name === name);
    return item ? item : null;
  };

  // function to find all meals containing a given item on the shoppingList
  const findMealsContainingItem = (itemName) => {
    // console.log('findMealsContainingItem:', itemName);
    const mealsWithItem = meals.filter((meal) =>
      meal.shoppingList.some((item) => item.name === itemName)
    );
    // console.log('mealsWithItem:', mealsWithItem);
    return mealsWithItem;
  };

  // function to update an item by name on the shoppinglist of a given meal id
  const updateItemInMeal = async (
    mealId,
    originalItemName,
    updatedItemData
  ) => {
    const { id, ...rest } = updatedItemData;
    // console.log('updateItemInMeal:', mealId, originalItemName, rest);
    const meal = meals.find((meal) => meal.id === mealId);
    // console.log('meal:', meal);
    const updatedShoppingList = meal.shoppingList.map((item) => {
      if (item.name === originalItemName) {
        return { ...item, ...rest };
      }
      return item;
    });
    // console.log('updatedShoppingList:', updatedShoppingList);
    const updatedMeal = { ...meal, shoppingList: updatedShoppingList };
    // console.log('updatedMeal:', updatedMeal);
    updateMeal(updatedMeal);
  };

  const deleteItem = async (data) => {
    // console.log('deleteItem:', JSON.stringify(data));
    try {
      await deleteDoc(doc(db, 'items', data.id));
    } catch (error) {
      console.error('Error deleting item: ', error);
    }
  };

  const addGroceryItem = async (newItem) => {
    // console.log('addGroceryItem:', data);
    const { id, ...rest } = newItem;
    const groceryList = lists.find((list) => list.id === 'Grocery');
    const isDuplicate = groceryList.items.some(
      (item) => item.name === newItem.name
    );
    if (isDuplicate) {
      // console.log('Item already exists in the grocery list.');
      return;
    }
    const groceryListDocRef = doc(db, 'lists', 'Grocery');
    const updatedGroceryList = [...groceryList.items, rest];
    try {
      await updateDoc(groceryListDocRef, { items: updatedGroceryList });
    } catch (error) {
      console.error('Error adding meal: ', error);
    }
  };

  const updateGroceryItem = async (name, origName, locations) => {
    // console.log('updateGroceryItem:', name, origName);
    const groceryList = lists.find((list) => list.id === 'Grocery');
    const groceryListDocRef = doc(db, 'lists', 'Grocery');
    const updatedGroceryList = groceryList.items.map((item) => {
      if (item.origName === origName || item.name === origName) {
        return {
          ...item,
          name: name,
          origName: origName,
          locations: locations,
        };
      } else {
        return item;
      }
    });
    try {
      await updateDoc(groceryListDocRef, { items: updatedGroceryList });
    } catch (error) {
      console.error('Error updating grocery item: ', error);
    }
  };

  const bulkAddGroceryItems = async (data) => {
    // console.log('bulkAddGroceryItem:', JSON.stringify(data));
    try {
      const groceryList = lists.find((list) => list.id === 'Grocery');
      // console.log('groceryList:', groceryList);
      const groceryListDocRef = doc(db, 'lists', 'Grocery');

      // Helper function to normalize item names
      const normalizeName = (name) => name.trim().toLowerCase();

      // Function to remove duplicates based on normalized names
      const removeDuplicates = (items) => {
        const seen = new Set();
        return items.filter((item) => {
          const normalizedName = normalizeName(item.name);
          if (seen.has(normalizedName)) {
            return false;
          } else {
            seen.add(normalizedName);
            return true;
          }
        });
      };

      // Remove duplicates within the existing items
      const existingItems = removeDuplicates(groceryList.items);

      // Remove duplicates within the new items
      const newItems = removeDuplicates(data);

      // Combine existing items with new items, ensuring no duplicates
      const uniqueNewItems = newItems.filter(
        (newItem) =>
          !existingItems.some(
            (existingItem) =>
              normalizeName(existingItem.name) === normalizeName(newItem.name)
          )
      );

      const updatedItems = [...existingItems, ...uniqueNewItems];

      // Perform a single update operation
      await updateDoc(groceryListDocRef, { items: updatedItems });
    } catch (error) {
      console.error('Error adding meal: ', error);
    }
  };

  const removeGroceryItem = async (name) => {
    // console.log('removeGroceryItem:', name);
    // const { id, ...rest } = JSON.parse(data);
    const groceryList = lists.find((list) => list.id === 'Grocery');
    const groceryListDoc = doc(db, 'lists', 'Grocery');
    const updatedGroceryList = groceryList.items.filter(
      (item) => item.name !== name
    );
    try {
      await updateDoc(groceryListDoc, { items: updatedGroceryList });
    } catch (error) {
      console.error('Error adding meal: ', error);
    }
  };

  const addListItem = async (listType, newItem) => {
    const { id, ...rest } = newItem;
    const targetList = lists.find((list) => list.id === listType);

    // Check for duplicates based on item name
    const isDuplicate = targetList.items.some(
      (item) => item.name === newItem.name
    );
    if (isDuplicate) {
      console.log(`Item already exists in the ${listType} list.`);
      return;
    }

    const listDocRef = doc(db, 'lists', listType); // Dynamic document reference
    const updatedList = [...targetList.items, rest];

    try {
      await updateDoc(listDocRef, { items: updatedList });
    } catch (error) {
      console.error(`Error adding item to ${listType}: `, error);
    }
  };

  const updateListItem = async (listType, name, origName, locations) => {
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'lists', listType);
    const updatedList = targetList.items.map((item) => {
      if (item.origName === origName || item.name === origName) {
        return {
          ...item,
          name: name,
          origName: origName,
          locations: locations,
        };
      } else {
        return item;
      }
    });
    try {
      await updateDoc(listDocRef, { items: updatedList });
    } catch (error) {
      console.error(`Error updating ${listType} item: `, error);
    }
  };

  const bulkAddListItems = async (listType, data) => {
    try {
      const targetList = lists.find((list) => list.id === listType);
      const listDocRef = doc(db, 'lists', listType);

      const normalizeName = (name) => name.trim().toLowerCase();

      const removeDuplicates = (items) => {
        const seen = new Set();
        return items.filter((item) => {
          const normalizedName = normalizeName(item.name);
          if (seen.has(normalizedName)) {
            return false;
          } else {
            seen.add(normalizedName);
            return true;
          }
        });
      };

      const existingItems = removeDuplicates(targetList.items);
      const newItems = removeDuplicates(data);

      const uniqueNewItems = newItems.filter(
        (newItem) =>
          !existingItems.some(
            (existingItem) =>
              normalizeName(existingItem.name) === normalizeName(newItem.name)
          )
      );

      const updatedItems = [...existingItems, ...uniqueNewItems];
      await updateDoc(listDocRef, { items: updatedItems });
    } catch (error) {
      console.error(`Error adding items to ${listType}: `, error);
    }
  };

  const removeListItem = async (listType, name) => {
    const targetList = lists.find((list) => list.id === listType);
    const listDocRef = doc(db, 'lists', listType);
    const updatedList = targetList.items.filter((item) => item.name !== name);
    try {
      await updateDoc(listDocRef, { items: updatedList });
    } catch (error) {
      console.error(`Error removing item from ${listType}: `, error);
    }
  };

  const addStore = async (newStore) => {
    // console.log('addStore:', data);
    const stores = lists.find((list) => list.id === 'Stores');
    // Check for duplicates based on store name
    const isDuplicate = stores.items.some(
      (store) => store.name === newStore.name
    );
    if (isDuplicate) {
      console.log(`Store ${newStore.name} already exists.`);
      return;
    }
    const storesDoc = doc(db, 'lists', 'Stores');
    const updatedStores = [...stores.items, newStore];
    try {
      await updateDoc(storesDoc, { items: updatedStores });
      await createOrVerifyStoreTypeList(newStore.type, newStore.name);
    } catch (error) {
      console.error('Error adding store: ', error);
    }

    async function createOrVerifyStoreTypeList(storeType, storeName) {
      const isTypeDuplicate = lists.find((list) => list.id === storeType);
      if (!isTypeDuplicate) {
        // create a new document in the lists collection with a specific ID
        const newStoreType = {
          // id: storeType,
          items: [],
        };
        // Use setDoc to ensure the document ID is set as 'type'
        await setDoc(doc(db, 'lists', storeType), newStoreType);
        // updateSettings({
        //   [`default_${storeType.toLowerCase()}`]: storeName,
        // });
      }
    }
  };

  const updateStore = async (originalStoreName, data) => {
    // console.log('updateStore-data:', JSON.stringify(data));
    // remove empty aisles
    data.aisles = data.aisles.filter((aisle) => aisle.name !== '');
    // console.log('cleaned-data:', JSON.stringify(data));

    const stores = lists.find((list) => list.id === 'Stores');
    const storesDoc = doc(db, 'lists', 'Stores');
    const updatedStores = stores.items.map((store) => {
      if (store.name === originalStoreName) {
        return { ...store, ...data };
      }
      return store;
    });

    try {
      await updateDoc(storesDoc, { items: updatedStores });
    } catch (error) {
      console.error('Error updating store: ', error);
    }

    // update this store for the items.locations where it is referenced
    const itemsWithStore = findItemsContainingStore(originalStoreName);
    // console.log('itemsWithStore:', itemsWithStore);
    itemsWithStore.forEach((item) => {
      updateStoreInItem(item.id, originalStoreName, data.name);
    });
  };

  const removeStore = async (name) => {
    // console.log('removeStore:', name);
    const stores = lists.find((list) => list.id === 'Stores');
    const storesDoc = doc(db, 'lists', 'Stores');
    const updatedStores = stores.items.filter((store) => store.name !== name);
    try {
      await updateDoc(storesDoc, { items: updatedStores });
    } catch (error) {
      console.error('Error adding store: ', error);
    }
  };

  // function to find all items containing a given store name
  const findItemsContainingStore = (storeName) => {
    // console.log('findItemsContainingStore:', storeName);
    const itemsWithStore = items.filter((item) =>
      item.locations.some((location) => location.store === storeName)
    );
    // console.log('itemsWithStore:', itemsWithStore);
    return itemsWithStore;
  };

  // function to update a store by name in an item's locations list
  const updateStoreInItem = async (
    itemId,
    originalStoreName,
    updatedStoreName
  ) => {
    const item = items.find((item) => item.id === itemId);
    // console.log('item:', item);
    const updatedLocations = item.locations.map((location) => {
      if (location.store === originalStoreName) {
        return { ...location, store: updatedStoreName };
      }
      return location;
    });
    // console.log('updatedLocations:', updatedLocations);
    const updatedItem = { ...item, locations: updatedLocations };
    // console.log('updatedItem:', updatedItem);
    updateItem(updatedItem);
  };

  const updateSettings = async (data) => {
    // console.log('updateSettings:', data);
    const settingsDoc = doc(db, 'lists', 'Settings');
    try {
      await updateDoc(settingsDoc, { ...data });
    } catch (error) {
      console.error('Error updating settings: ', error);
    }
  };

  const updateStoreSortOrder = async (data) => {
    // { storeName: 'Shaws Windham', sortOrder: { columnKey: 'aisle', order: 'ascend' }}
    // console.log('updateStoreSortOrder:', data);
    const storesDoc = doc(db, 'lists', 'Stores');
    const updatedStores = stores?.items?.map((store) => {
      if (store.name === data.storeName) {
        return {
          ...store,
          sortOrder: {
            ...data.sortOrder,
          },
        };
      }
      return store;
    });
    try {
      // console.log('updatedItems', updatedStores);
      await updateDoc(storesDoc, { items: updatedStores });
      // await updateDoc(storesDoc, { ...updatedItems });
    } catch (error) {
      console.error('Error updating settings: ', error);
    }
  };

  const updateUserPrefs = async (uid, newPrefs) => {
    // console.log('updateUserPrefs:', { [uid]: newPrefs });
    setUserPrefs((prevState) => ({
      ...prevState,
      [uid]: {
        ...prevState[uid],
        ...newPrefs,
      },
    }));
    // console.log('getUserPrefs-adding prefs:', { [uid]: newPrefs });
    try {
      updateSettings({
        userPrefs: {
          ...settings.userPrefs,
          [uid]: { ...settings.userPrefs[uid], ...newPrefs },
        },
      });
    } catch (error) {
      console.error('Error updating settings: ', error);
    }
  };

  const getUserPrefs = (uid) => {
    // console.log('getUserPrefs-uid:', uid);
    if (!settings.userPrefs?.[uid]) {
      const newPrefs = {
        email: auth.currentUser.email,
        color_pref: 'dark',
      };
      updateUserPrefs(uid, newPrefs);
    } else {
      // console.log('getUserPrefs-retrieved prefs:', settings.userPrefs?.[uid]);
      setUserPrefs(settings.userPrefs);
      // return settings.userPrefs?.[uid];
    }
  };

  const updateMealHistory = async (data) => {
    // console.log('updateMealHistory:', JSON.stringify(data, null, 2));
    // const historyDoc = doc(db, 'history', 'meals');
    const today = new Date();
    const weekNumber = getWeekNumber(today);
    try {
      // await updateDoc(historyDoc, { ...data });
      await addDoc(collection(db, 'history'), {
        week: weekNumber,
        meals: data,
      });
    } catch (error) {
      console.error('Error adding meal: ', error);
    }
  };

  const updateShoppingHistory = async (data) => {
    // console.log('updateShoppingHistory:', JSON.stringify(data, null, 2));
    const today = new Date();
    const weekNumber = getWeekNumber(today);
    try {
      await addDoc(collection(db, 'history'), {
        week: weekNumber,
        item: data,
      });
    } catch (error) {
      console.error('Error adding item: ', error);
    }
  };

  function getWeekNumber(date) {
    // Copy date so we don't modify the original date
    const copiedDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );

    // Set to the nearest Thursday: current date + 4 - current day number (0-6) (week starts on Monday)
    copiedDate.setUTCDate(
      copiedDate.getUTCDate() + 4 - (copiedDate.getUTCDay() || 7)
    );

    // Get first day of the year
    const yearStart = new Date(Date.UTC(copiedDate.getUTCFullYear(), 0, 1));

    // Calculate full weeks to the nearest Thursday
    const weekNumber = Math.ceil(
      ((copiedDate.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );

    return weekNumber;
  }

  const values = {
    loading,
    userPrefs,
    getUserPrefs,
    updateUserPrefs,
    settings,
    updateSettings,
    menus,
    updateMenu,
    meals,
    addMeal,
    updateMeal,
    deleteMeal,
    items,
    addItem,
    updateItem,
    deleteItem,
    getItemByName,
    lists,
    addGroceryItem,
    bulkAddGroceryItems,
    updateGroceryItem,
    removeGroceryItem,
    addListItem,
    bulkAddListItems,
    updateListItem,
    removeListItem,
    findMealsContainingItem,
    findItemsContainingStore,
    addStore,
    updateStore,
    removeStore,
    storeTypes,
    updateStoreSortOrder,
    updateMealHistory,
    updateShoppingHistory,
  };

  return <DataContext.Provider value={values}>{children}</DataContext.Provider>;
};

export { DataProvider, DataContext };
