const MealSlip = ({ menuSlot, mealData, onSelectItem }) => {
  // console.log('run MealSlip...');
  const menuWeek = global.config.menuWeek;
  const currentDayOfWeek = global.config.currentDayOfWeek;
  const day = menuSlot < 8 ? menuWeek[menuSlot - 1] : menuWeek[menuSlot - 8];
  const today = day === currentDayOfWeek && menuSlot < 8 ? 'menu-today' : '';
  const classes = `px-1 ${today}`;

  // console.log('menuSlot:', menuSlot);
  // console.log('mealData:', JSON.stringify(mealData));

  if (mealData?.hasOwnProperty('meal')) {
    return (
      <div
        className="list-group-item list-group-item-action"
        onClick={() => onSelectItem(menuSlot, mealData)}
      >
        <div className="d-flex w-100 justify-content-between">
          <h5 className="mealButton mb-0">{mealData.meal.name}</h5>
          <small className={classes}>{day}</small>
        </div>
        {(mealData.meal.sides.length > 0 && mealData.meal.sides[0] !== '' && (
          <div>w/{mealData.meal.sides.join(', ')}</div>
        )) || <div>&nbsp;</div>}
        {/* {mealData[menuSlot].cocktails.length > 0 && (
          <div>
            <small>
              <b>Drinks:</b> {mealData[menuSlot].cocktails.join(', ')}
            </small>
          </div>
        )} */}
      </div>
    );
  } else {
    return (
      <div
        className="list-group-item list-group-item-action"
        onClick={() => onSelectItem(menuSlot, mealData)}
      >
        <div className="d-flex w-100 justify-content-between">
          <h5 className="addMealButton">Tap to Add</h5>
          <small>{day}</small>
        </div>
      </div>
    );
  }
};

export default MealSlip;
