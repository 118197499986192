import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { Table } from 'antd';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { DataContext } from './DataProvider';
import { PlusSquareFill, DashSquareFill } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';

const Groceries = ({ shoppingMode }) => {
  const { currentUser } = useAuth();

  // Use context to get data and methods from DataContext
  const {
    items,
    lists,
    addGroceryItem,
    removeGroceryItem,
    bulkAddGroceryItems,
    updateGroceryItem,
    getItemByName,
    updateItem,
    menus,
    meals,
    settings,
    updateSettings,
    userPrefs,
    updateShoppingHistory,
    updateStoreSortOrder,
  } = useContext(DataContext);

  // State to manage selected grocery store
  const [groceryStore, setGroceryStore] = useState(null);
  const [hasAisles, setHasAisles] = useState(false);
  // State to manage selected item for adding to grocery list
  const [selectedItem, setSelectedItem] = useState(null);
  // State to manage selected meal for adding to grocery list
  const [selectedMeal, setSelectedMeal] = useState(null);
  // State to manage the visibility of the modals
  const [groceryModalIsOpen, setGroceryModalIsOpen] = useState(false);
  const [itemModalIsOpen, setItemModalIsOpen] = useState(false);
  const [aisleModalIsOpen, setAisleModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    origName: '',
    locations: [],
    origLocations: [],
  });

  const [sortOrder, setSortOrder] = useState({
    columnKey: 'aisle',
    order: 'ascend',
  });

  // Update the groceryStore state when settings are available
  useEffect(() => {
    if (settings?.default_grocery) {
      setGroceryStore({
        value: settings.default_grocery,
        label: settings.default_grocery,
      });
    }
  }, [settings]);

  useEffect(() => {
    const storeList = lists.find((list) => list.id === 'Stores');
    if (groceryStore) {
      const savedSortOrder = storeList.items.find(
        (store) => store.name === groceryStore?.value
      )?.sortOrder;
      if (savedSortOrder !== undefined) {
        setSortOrder(savedSortOrder);
      } else {
        setSortOrder({
          columnKey: 'aisle',
          order: 'ascend',
        });
      }

      // check if the groceryStore has aisles defined
      setHasAisles(
        storeList.items.find((store) => store.name === groceryStore?.value)
          ?.aisles?.length > 0
      );
    }
  }, [lists, groceryStore]);

  // Find the grocery list from lists
  const groceryList = lists.find((list) => list.id === 'Grocery');

  // List of stores with type 'Grocery'
  const stores = lists.find((list) => list.id === 'Stores');
  const groceryStores = stores?.items
    ? [...stores.items].filter((store) => store.type === 'Grocery')
    : [];
  // console.log('groceryStores:', groceryStores);

  // Sort items and meals by name
  const sortedItems = [...items].sort((a, b) => a.name.localeCompare(b.name));
  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));

  // Toggle the visibility of item selection group
  const toggleItemSelect = () => {
    const addItemPickerGroup = document.getElementById('addItemPickerGroup');
    const plusSquare = document.getElementById('plusSquare');
    const dashSquare = document.getElementById('dashSquare');
    addItemPickerGroup.classList.toggle('d-none');
    plusSquare.classList.toggle('d-none');
    dashSquare.classList.toggle('d-none');
  };

  // Handle adding a single grocery item
  const handleAddGroceryItem = (item) => {
    addGroceryItem(item);
    setSelectedItem(null);
  };

  // Handle adding a meal's grocery items
  const handleAddGroceryMeal = (meal) => {
    bulkAddGroceryItems(meal.shoppingList);
    setSelectedMeal(null);
  };

  // Handle selecting an item on the list. edit if not in shopping mode
  const handleSelectItem = (item) => {
    // console.log('handleSelectItem:', item);
    const storeHasAisles =
      stores.items.find((store) => store.name === groceryStore?.value).aisles
        .length > 0;
    const itemHasAisle = item.locations
      .find((location) => location.store === groceryStore?.value)
      ?.aisle.toString();

    if (shoppingMode) {
      if (storeHasAisles && !itemHasAisle) {
        // the store has aisles defined, but this item is not assigned
        // offer the user a chance to update the aisle when shopping
        openAisleModal(item);
      } else {
        // add to history here...
        handleRemoveGroceryItem(item.name);
      }
    } else {
      openItemModal(item);
    }
  };

  // Handle removing a grocery item
  const handleRemoveGroceryItem = (item) => {
    // console.log('handleRemoveGroceryItem:', item);
    if (shoppingMode) {
      console.log('Adding item to shopping history...');
      const updatedItem = {
        item: item,
        store: groceryStore?.value,
        date: new Date().toLocaleDateString('en-US'), // format date as YYYY-MM-DD
      };
      updateShoppingHistory(updatedItem);
    }
    removeGroceryItem(item);
  };

  // Handle removing a grocery item from the item modal
  const handleRemoveGroceryItemByModal = (item) => {
    handleRemoveGroceryItem(item);
    closeItemModal();
  };

  // Handle removing a grocery item from the aisle modal
  const handleRemoveGroceryItemByAisleModal = (item) => {
    // add to history here...
    handleRemoveGroceryItem(item);
    closeAisleModal();
  };

  // Prepare store options for the select dropdown
  const storeOptions = groceryStores.map((store) => ({
    value: store.name,
    label: store.name,
  }));

  // Open the grocery modal
  const openGroceryModal = () => {
    setGroceryModalIsOpen(true);
  };

  // Handle changing the selected grocery store
  const handleGroceryStoreChange = (selectedOption) => {
    setGroceryStore(selectedOption);
    updateSettings({ default_grocery: selectedOption.value });
    closeGroceryModal();
  };

  // Close the modal
  const closeGroceryModal = () => {
    setGroceryModalIsOpen(false);
  };

  // Open the item modal
  const openItemModal = (item) => {
    setSelectedItem(item); // this may not be needed anymore... maybe for delete...
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setItemModalIsOpen(true);
  };

  // Handle changing the item name
  const handleItemNameChange = (e) => {
    setFormData({ ...formData, name: e.target.value });
  };

  // Handle changing the item Aisle #
  const handleItemAisleChange = (e) => {
    // console.log('aisle selected:', e.target.value);
    // console.log('form data:', JSON.stringify(formData, null, 2));

    const storeExists = formData.locations.some(
      (location) => location.store === groceryStore?.value
    );

    if (storeExists) {
      // update the aisle of the groceryStore in the formData
      const updatedLocations = formData.locations.map((location) =>
        location.store === groceryStore?.value
          ? { ...location, aisle: Number(e.target.value) }
          : location
      );
      setFormData({ ...formData, locations: updatedLocations });
    } else {
      // add the store and aisle to the formData
      const newLocation = {
        store: groceryStore?.value,
        aisle: Number(e.target.value),
      };
      const updatedLocations = [...formData.locations, newLocation];
      setFormData({
        ...formData,
        locations: updatedLocations,
      });
    }
  };

  // Handle submitting the item form
  const handleItemSubmit = async (e) => {
    e.preventDefault();
    const { name, origName, locations, origLocations } = formData;
    if (name === origName && locations === origLocations) {
      // nothing changed, just close the modal
    } else if (name !== origName && locations === origLocations) {
      // the name changed, just update the grocery list
      updateGroceryItem(name, origName, locations);
    } else if (name === origName && locations !== origLocations) {
      // the aisle changed, just update the aisle # for the current store
      itemAisleUpdater();
    } else if (name !== origName && locations !== origLocations) {
      // both name and locations changed
      await updateGroceryItem(name, origName, locations);
      itemAisleUpdater();
    }
    setItemModalIsOpen(false);

    function itemAisleUpdater() {
      let updatedItem = getItemByName(origName);
      updateItem({ ...updatedItem, locations: locations });
      updateGroceryItem(name, origName, locations);
    }
  };

  // Close the item modal
  const closeItemModal = () => {
    setItemModalIsOpen(false);
    setSelectedItem(null);
  };

  // Open the aisle modal
  const openAisleModal = (item) => {
    setSelectedItem(item); // this may not be needed anymore...
    setFormData({
      name: item.name,
      origName: item.origName || item.name,
      locations: item.locations,
      origLocations: item.origLocations || item.locations,
    });
    setAisleModalIsOpen(true);
  };

  // Close the item modal
  const closeAisleModal = () => {
    setAisleModalIsOpen(false);
    setSelectedItem(null);
  };

  // Handle submitting the item form
  const handleAisleSubmit = async (e) => {
    e.preventDefault();
    const { origName, locations, origLocations } = formData;
    if (locations !== origLocations) {
      // the aisle changed, update the aisle # for the current store
      let updatedItem = getItemByName(origName);
      updateItem({ ...updatedItem, locations: locations });
    }
    await handleRemoveGroceryItemByAisleModal(selectedItem.name);
    setAisleModalIsOpen(false);
  };

  // Add this week's shopping list to the grocery list
  const addThisWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu?.meals?.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum < 8 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    return bulkAddGroceryItems(newShoppingList);
  };

  // Add next week's shopping list to the grocery list
  const addNextWeeksShoppingList = async () => {
    const mealsOnMenu = menus.find((menu) => menu.id === 'dinner');
    var newShoppingList = [];
    mealsOnMenu.meals.forEach((mealObj) => {
      if (
        mealObj &&
        mealObj.meal &&
        mealObj.slotNum > 7 &&
        mealObj.meal.shoppingList
      ) {
        newShoppingList = newShoppingList.concat(mealObj.meal.shoppingList);
      }
    });
    return bulkAddGroceryItems(newShoppingList);
  };

  // Handle sort change
  const handleSort = async (columnKey) => {
    const order =
      sortOrder.columnKey === columnKey && sortOrder.order === 'ascend'
        ? 'descend'
        : 'ascend';
    setSortOrder({ columnKey, order });

    const updatedData = {
      storeName: groceryStore?.value,
      sortOrder: { columnKey, order },
    };
    await updateStoreSortOrder(updatedData);
    // console.log('sort order:', { columnKey, order });
  };

  // Define columns for the grocery table
  const col1 = {
    title: 'Aisle',
    dataIndex: 'aisle',
    width: '10%',
    align: 'center',
    sortOrder: sortOrder.columnKey === 'aisle' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('aisle'),
    }),
    sorter: (a, b) => a.aisle - b.aisle,
  };

  const col2 = {
    title: 'Item Name',
    dataIndex: 'name',
    sortOrder: sortOrder.columnKey === 'name' && sortOrder.order,
    onHeaderCell: (column) => ({
      onClick: () => handleSort('name'),
    }),
    sorter: (a, b) => a.name.localeCompare(b.name),
  };

  const tableColumns = hasAisles ? [col1, col2] : [col2];

  // Prepare data for the grocery table
  const tableData = groceryList?.items?.map((item) => ({
    item: item,
    name: item.name,
    aisle:
      item.locations.length > 0 &&
      item.locations.find((location) => location.store === groceryStore?.value)
        ? item.locations.find(
            (location) => location.store === groceryStore.value
          ).aisle
        : '',
  }));

  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  if (!groceryStore) {
    return <div></div>; // or a loading indicator
  }

  return (
    <div className="mb-5 container-fluid">
      <nav className="navbar shopper-navbar">
        <div className="container p-0">
          <div
            onClick={openGroceryModal}
            className={'h4 pb-0 mb-0 text-' + colorPref}
          >
            {groceryStore.label}
          </div>
          <div className={'navbar-nav ml-auto text-' + colorPref}>
            <PlusSquareFill
              id="plusSquare"
              onClick={() => toggleItemSelect()}
              size={32}
            />
            <DashSquareFill
              id="dashSquare"
              className="d-none"
              onClick={() => toggleItemSelect()}
              size={32}
            />
          </div>
        </div>
      </nav>

      <div className="mb-3 d-none" id="addItemPickerGroup">
        <InputGroup className="mb-1">
          <InputGroup.Text className="groceryPicker" style={{ width: '38%' }}>
            Add Single Item
          </InputGroup.Text>
          <Select
            className="flex-grow-top"
            id="addItemPicker"
            value={selectedItem}
            onChange={(picker) => handleAddGroceryItem(picker.value)}
            options={sortedItems.map((item) => ({
              value: item,
              label: item.name,
            }))}
            placeholder="Select an item"
          />
        </InputGroup>
        <InputGroup className="mb-1">
          <InputGroup.Text className="groceryPicker" style={{ width: '38%' }}>
            Add Meal Items
          </InputGroup.Text>
          <Select
            className="flex-grow-bottom"
            id="addMealPicker"
            value={selectedMeal}
            onChange={(picker) => handleAddGroceryMeal(picker.value)}
            options={sortedMeals.map((meal) => ({
              value: meal,
              label: meal.name,
            }))}
            placeholder="Select a meal"
          />
        </InputGroup>
        <ButtonGroup className="w-100 gap-1">
          <Button
            id="addThisWeeksShoppingList-button"
            variant="secondary"
            className="addMenu-button"
            onClick={() => addThisWeeksShoppingList()}
          >
            Add <b>This Week's</b> Menu
          </Button>
          <Button
            id="addNextWeeksShoppingList-button"
            className="addMenu-button"
            variant={colorPref}
            onClick={() => addNextWeeksShoppingList()}
          >
            Add <b>Next Week's</b> Menu
          </Button>
        </ButtonGroup>
      </div>

      <Table
        columns={tableColumns}
        dataSource={tableData}
        pagination={false}
        rowKey={(r) => r.name}
        onRow={(r) => {
          return {
            onClick: () => handleSelectItem(r.item), // click row
          };
        }}
      />

      <Modal
        id="groceryModal"
        show={groceryModalIsOpen}
        onHide={closeGroceryModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            Select a Grocery Store
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            className="mb-3"
            value={groceryStore}
            onChange={handleGroceryStoreChange}
            options={storeOptions}
            placeholder="Select a store"
          />
        </Modal.Body>
      </Modal>

      <Modal
        id="itemModal"
        show={itemModalIsOpen}
        onHide={closeItemModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleItemSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Edit Item</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name:</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleItemNameChange}
                required
              />
            </Form.Group>
            {/* only show the picker if the store has aisles defined */}
            {stores.items.find((store) => store.name === groceryStore?.value)
              .aisles.length > 0 ? (
              <>
                <Form.Label>Aisle @ {groceryStore?.value}:</Form.Label>
                <InputGroup className="mb-1 ig-stores">
                  <Form.Select
                    name={groceryStore?.value}
                    value={
                      formData.locations
                        .find(
                          (location) => location.store === groceryStore?.value
                        )
                        ?.aisle.toString() || ''
                    }
                    onChange={handleItemAisleChange}
                  >
                    <option value="" disabled></option>
                    {stores.items
                      .find((store) => store.name === groceryStore?.value)
                      .aisles.sort((a, b) => a.num - b.num) // Sort aisles by aisle number
                      .map((aisle) => (
                        <option key={aisle.name} value={aisle.num}>
                          {aisle.num + ' ' + aisle.name}
                        </option>
                      ))}
                  </Form.Select>
                </InputGroup>
              </>
            ) : (
              <div>
                No aisles defined for{' '}
                <Link to="/lists/stores">{groceryStore?.value}</Link> 🙄
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Save
              </Button>
              <Button
                variant={colorPref === 'danger' ? 'warning' : 'danger'}
                className="mr-2"
                onClick={() =>
                  handleRemoveGroceryItemByModal(selectedItem.name)
                }
              >
                Remove
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={closeItemModal}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        id="aisleModal"
        show={aisleModalIsOpen}
        onHide={closeAisleModal}
        backdrop="static"
        keyboard={false}
        restoreFocus={false}
      >
        <Form onSubmit={handleAisleSubmit} autoComplete="off">
          <Modal.Header closeButton>
            <Modal.Title className={'text-' + colorPref}>Add Aisle</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="mb-3">
              <small>
                Hey Buddy... Since you're adding{' '}
                <b className={'text-' + colorPref}>{selectedItem?.name}</b> to
                your cart, why not add the aisle number so it's easier to find
                next time.
              </small>
            </div>
            <Form.Label>Aisle @ {groceryStore?.value}:</Form.Label>
            <InputGroup className="mb-1 ig-stores">
              <Form.Select
                name={groceryStore?.value}
                value={
                  formData.locations
                    .find((location) => location.store === groceryStore?.value)
                    ?.aisle.toString() || ''
                }
                onChange={handleItemAisleChange}
              >
                <option value="" disabled></option>
                {stores.items
                  .find((store) => store.name === groceryStore?.value)
                  .aisles.sort((a, b) => a.num - b.num) // Sort aisles by aisle number
                  .map((aisle) => (
                    <option key={aisle.name} value={aisle.num}>
                      {aisle.num + ' ' + aisle.name}
                    </option>
                  ))}
              </Form.Select>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-grid gap-2 d-flex justify-content-end">
              <Button variant={colorPref} className="mr-2" type="submit">
                Update
              </Button>
              <Button
                variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
                onClick={() =>
                  handleRemoveGroceryItemByAisleModal(selectedItem?.name)
                }
              >
                Not Now
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

export default Groceries;
