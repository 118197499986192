import { useContext, useState } from 'react';
import { useAuth } from './AuthContext';
// import { useNavigate } from 'react-router-dom';
import { DataContext } from './DataProvider';
import Modal from 'react-bootstrap/Modal';
import MealSlip from './MealSlip';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';

const Menus = () => {
  // console.log('run menus...');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [promoteModalIsOpen, setPromoteModalIsOpen] = useState(false);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState('');
  const [leftovers, setLeftovers] = useState([]);

  const { menus, updateMenu, meals, userPrefs, updateMealHistory } =
    useContext(DataContext);

  const sortedMeals = [...meals].sort((a, b) => a.name.localeCompare(b.name));
  const leftOverSortedMeals = [...leftovers, ...sortedMeals];
  const dinnerData = menus.find((item) => item.id === 'dinner');

  const { currentUser } = useAuth();
  const colorPref = userPrefs?.[currentUser?.uid]?.color_pref;

  const openModal = (slot, data) => {
    // console.log('run openModal... slot: ', parseInt(slot), ' data: ', data);
    setCurrentSlot(parseInt(slot));
    addLeftovers(parseInt(slot));
    setSelectedMeal(data?.meal?.name || '');
    setModalIsOpen(true);
  };

  const addLeftovers = (slot) => {
    var newLeftovers = [];
    if (slot < 8 && slot > 1) {
      while (slot > 1) {
        const thisSlot = slot;
        const lastMeal = dinnerData.meals.filter(
          (meal) => meal && meal.slotNum === thisSlot - 1
        )[0];
        // console.log('lastMeal: ', lastMeal);
        lastMeal?.meal?.name &&
          !lastMeal.meal.name.startsWith('lo:') &&
          newLeftovers.push({
            name: 'lo: ' + lastMeal.meal.name,
            sides: lastMeal.meal.sides || [],
            shoppingList: [],
            id: '',
          });
        slot--;
      }
    } else if (slot < 15 && slot > 8) {
      while (slot > 8) {
        const thisSlot = slot;
        const lastMeal = dinnerData.meals.filter(
          (meal) => meal && meal.slotNum === thisSlot - 1
        )[0];
        // console.log('lastMeal: ', lastMeal);
        lastMeal?.meal?.name &&
          !lastMeal.meal.name.startsWith('lo:') &&
          newLeftovers.push({
            name: 'lo: ' + lastMeal.meal.name,
            sides: lastMeal.meal.sides || [],
            shoppingList: [],
            id: '',
          });
        slot--;
      }
    }
    // console.log('newLeftovers: ', newLeftovers);
    setLeftovers(newLeftovers);
  };

  const closeModal = () => {
    // console.log('run closeModal...');
    setModalIsOpen(false);
    setCurrentSlot(null);
    setSelectedMeal('');
  };

  const openPromoteModal = () => {
    // console.log('run openPromoteModal...');
    setPromoteModalIsOpen(true);
  };

  const closePromoteModal = () => {
    // console.log('run closePromoteModal...');
    setPromoteModalIsOpen(false);
  };

  const handleSelectMeal = (e) => {
    setSelectedMeal(e.target.value);
  };

  const handleSave = async () => {
    // console.log('run handleSave...');

    var selectedMealData = null;
    const thisMeal = meals.find((meal) => meal.name === selectedMeal);
    if (!thisMeal) {
      selectedMealData = leftovers.find((meal) => meal.name === selectedMeal);
    } else {
      selectedMealData = thisMeal;
    }
    const { id, ...rest } = selectedMealData;
    const updatedMenu = [...dinnerData.meals];
    updatedMenu[currentSlot - 1] = {
      slotNum: currentSlot,
      meal: rest,
    };
    updateMenu(updatedMenu);
    closeModal();
  };

  const handleRemove = async () => {
    // console.log('run handleRemove...');
    const updatedMenu = [...dinnerData.meals];
    updatedMenu[currentSlot - 1] = null;
    // console.log('updatedMenu: ' + updatedMenu);
    updateMenu(updatedMenu);
    closeModal();
  };

  const promoteMenu = async () => {
    const meals = dinnerData.meals;
    // console.log('meals: ' + JSON.stringify(meals, null, 2));

    // add last week's meals to the history collection
    const today = new Date();
    const historyMeals = meals
      .filter((meal) => meal)
      .filter((meal) => meal.slotNum >= 1 && meal.slotNum <= 7)
      .map((meal) => {
        const date = new Date();
        date.setDate(today.getDate() - (8 - meal.slotNum));
        return {
          name: meal.meal.name,
          sides: meal.meal.sides,
          date: date.toLocaleDateString('en-US'), // format date as YYYY-MM-DD
        };
      });
    updateMealHistory(historyMeals);

    // Extract the meals from index 7 to 13 and update their slotNum if they are not null
    const updatedMenu = meals
      .slice(7, 14)
      .map((item) => (item ? { ...item, slotNum: item.slotNum - 7 } : null));

    // Fill the rest of the array with null values to match the original length
    const filledMenu = [
      ...updatedMenu,
      ...Array(meals.length - updatedMenu.length).fill(null),
    ];

    // console.log(JSON.stringify(filledMenu, null, 2));
    updateMenu(filledMenu);
    closePromoteModal();
  };

  // Convert meals to options format for react-select
  const mealOptions = leftOverSortedMeals.map((meal) => ({
    value: meal.name,
    label: meal.name,
  }));

  // Handle filter text change
  const handleChange = (selectedOption) => {
    handleSelectMeal({
      target: { value: selectedOption ? selectedOption.value : '' },
    });
  };

  // Find the selected option for react-select
  const selectedOption = mealOptions.find(
    (option) => option.value === selectedMeal
  );

  return (
    <div className="mt-4 mb-5 container-fluid">
      <h1 className={'pb-1 text-' + colorPref} style={{ paddingLeft: '12px' }}>
        This Week's Menu
      </h1>

      <div className="list-group pb-4">
        <MealSlip
          menuSlot="1"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 1)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="2"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 2)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="3"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 3)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="4"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 4)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="5"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 5)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="6"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 6)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="7"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 7)}
          onSelectItem={openModal}
        />
      </div>

      <h1 className={'pb-1 text-' + colorPref} style={{ paddingLeft: '12px' }}>
        Next Week's Menu
      </h1>

      <div className="list-group pb-4">
        <MealSlip
          menuSlot="8"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 8)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="9"
          mealData={dinnerData.meals.find((meal) => meal && meal.slotNum === 9)}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="10"
          mealData={dinnerData.meals.find(
            (meal) => meal && meal.slotNum === 10
          )}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="11"
          mealData={dinnerData.meals.find(
            (meal) => meal && meal.slotNum === 11
          )}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="12"
          mealData={dinnerData.meals.find(
            (meal) => meal && meal.slotNum === 12
          )}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="13"
          mealData={dinnerData.meals.find(
            (meal) => meal && meal.slotNum === 13
          )}
          onSelectItem={openModal}
        />
        <MealSlip
          menuSlot="14"
          mealData={dinnerData.meals.find(
            (meal) => meal && meal.slotNum === 14
          )}
          onSelectItem={openModal}
        />
      </div>

      <Button
        className="w-100 mb-4"
        variant={colorPref}
        onClick={openPromoteModal}
      >
        Promote Next Week's Menu to This Week
      </Button>

      <Modal
        show={modalIsOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>Select Meal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            className="mb-3"
            value={selectedOption}
            onChange={handleChange}
            options={mealOptions}
            placeholder="Select a meal"
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant={colorPref} className="mr-2" onClick={handleSave}>
            Save
          </Button>
          {selectedMeal !== '' && (
            <Button
              variant={colorPref === 'danger' ? 'warning' : 'danger'}
              className="mr-2"
              onClick={handleRemove}
            >
              Remove
            </Button>
          )}
          <Button
            variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={promoteModalIsOpen}
        onHide={closePromoteModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'text-' + colorPref}>
            Promote Menu
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          Heads-up, Buttercup! 😳 Are you sure you want to overwrite this week's
          menu? This action will update the meal history and cannot be undone.
        </Modal.Body>

        <Modal.Footer>
          <Button variant={colorPref} className="mr-2" onClick={promoteMenu}>
            Promote
          </Button>
          <Button
            variant={colorPref === 'secondary' ? 'dark' : 'secondary'}
            onClick={closePromoteModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Menus;
