const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const version = '0.1.8';
const todaysDate = new Date();
const options = { year: 'numeric', month: 'long', day: 'numeric' };
const formattedDate = todaysDate.toLocaleDateString('en-US', options); // Output: June 7, 2024

module.exports = global.config = {
  version: version,
  date: formattedDate,
  author: 'Chris Cook',
  email: 'chris@spycook.com',
  devDate: '©2024 Cookware™',
  logo: './mb-logo.png',
  menuWeek: ['Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu'],
  currentDayOfWeek: daysOfWeek[new Date().getDay()],
  defaultGroceryStore: 'Market Basket Londonderry',
};
